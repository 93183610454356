<template>
  <v-form>
    <v-card class="pa-4 mx-4 my-1" style="border-radius: 30px">
      <v-dialog
        v-model="dialogfilter"
        max-width="300px"
        style="border-radius: 30px"
      >
        <v-card style="border-radius: 30px" class="pa-4">
          <v-row align="center" justify="center" dense no-gutters>
            <v-col cols="12" class="text-center my-6">ตัวกรอง</v-col>

            <v-col cols="10" class="text-center">
              <v-autocomplete
                label="เลือกภาค"
                v-model="data.area"
                :items="items2"
                item-text="text"
                item-value="group"
                @change="onChangeArea(data.area)"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="10" class="text-center">
              <v-autocomplete
                label="เลือกจังหวัด"
                v-model="data.province"
                :items="items3"
                item-text="province"
                item-value="province"
                @change="onChangeProvince(data.province)"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="10" class="text-center">
              <v-autocomplete
                label="เลือกสถานี"
                v-model="data.station"
                :items="items4"
                item-text="station"
                item-value="station"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="10" class="mb-2"
              ><v-btn rounded block dark color="#" @click="clear()"
                >เคลียร์</v-btn
              ></v-col
            >
            <v-col cols="10"
              ><v-btn rounded block dark color="#633fe4" @click="getData()"
                >ค้นหา</v-btn
              ></v-col
            >
          </v-row>
        </v-card>
      </v-dialog>
      <!-- acceptFlag: 0
area: "ภ.1"
foundDrug: 0
joinUs: 0
joinUs100: 0
kumAmount: 0
kumLeaderAmount: 0
mou: 0
notFoundDrug: 0
peopleAcceptProject: 0
province: "ปทุมธานี"
station: "สภ.ปากคลองรังสิต"
sumHouse: 1
sumPeople14: "0"
sumPeopleReal: "0"
xray1: 0
xray100: 0
xrayed: 0 -->
      <v-row align="right">
        <v-col cols="3" class="text-right">
          <v-btn
            small
            rounded
            dense
            @click="dialogfilter = true"
            style="color: grey"
          >
            <v-icon>mdi-filter</v-icon>ตัวกรอง
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="9" class="text-right">
          <v-btn
            class="mr-2"
            rounded
            dense
            style="color: white"
            color="#866edd"
            @click="gotoQR()"
            >แจ้งเบาะแส หรือ ติดตามสถานะเบาะแส</v-btn
          >
          <!-- <v-btn class="mr-2" rounded dense style="color: white" color="#866edd"
            ></v-btn
          > -->
          <v-btn class="mr-2" rounded dense style="color: white" color="#866edd" @click="gotoAdmin()"
            >สำหรับเจ้าหน้าที่</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col id="nav" cols="3">
          <v-row no-gutters align="center" justify="center">
            <!-- <v-img src="@/assets/chum_logo.jpg" max-width="90px" /> -->
            <!-- <v-col cols="12" class="text-right pb-10 pt-2">
            
            </v-col> -->
            <span
              class="pl-4"
              style="font-size: 24px; font-weight: 900; color: #633fe4"
            >
              ระบบชุมชนยั่งยืน
            </span>

            <v-col cols="12" class="pt-4"> <v-divider /></v-col>
            <!-- {{ panel }}{{ i }} -->
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mt-4"
                  style="cursor: pointer"
                  @click="panel = 1"
                >
                  <span
                    :style="
                      panel == 1
                        ? 'font-size: 18px; font-weight: 900; color: #01a9f9'
                        : 'font-size: 18px; font-weight: 900; color: grey'
                    "
                  >
                    <v-icon>mdi-menu-right</v-icon>{{ items[0].text }}
                  </span>
                </v-col>
                <Transition>
                  <v-col
                    cols="12"
                    v-show="panel == 1"
                    style="text-align: right"
                  >
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                    >
                      <!-- {{ items[0].value }} -->
                      {{ value.sumPeopleReal | VMask(currencyMask) }} คน
                    </span>
                  </v-col>
                </Transition>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mt-4"
                  style="cursor: pointer"
                  @click="panel = 2"
                >
                  <span
                    :style="
                      panel == 2
                        ? 'font-size: 18px; font-weight: 900; color: #01a9f9'
                        : 'font-size: 18px; font-weight: 900; color: grey'
                    "
                  >
                    <v-icon>mdi-menu-right</v-icon>{{ items[1].text }}
                  </span>
                </v-col>
                <Transition>
                  <v-col
                    cols="12"
                    v-show="panel == 2"
                    style="text-align: right"
                  >
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                    >
                      <!-- {{ items[1].value }} -->
                      {{ value.xray1 | VMask(currencyMask) }} คน
                    </span>
                  </v-col>
                </Transition>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mt-4"
                  style="cursor: pointer"
                  @click="panel = 3"
                >
                  <span
                    :style="
                      panel == 3
                        ? 'font-size: 18px; font-weight: 900; color: #01a9f9'
                        : 'font-size: 18px; font-weight: 900; color: grey'
                    "
                  >
                    <v-icon>mdi-menu-right</v-icon>{{ items[2].text }}
                  </span>
                </v-col>
                <Transition>
                  <v-col
                    cols="12"
                    v-show="panel == 3"
                    style="text-align: right"
                  >
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                    >
                      <!-- {{ items[2].value }} -->
                      {{ value.xrayed | VMask(currencyMask) }} คน
                    </span>
                  </v-col>
                </Transition>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mt-4"
                  style="cursor: pointer"
                  @click="panel = 4"
                >
                  <span
                    :style="
                      panel == 4
                        ? 'font-size: 18px; font-weight: 900; color: #01a9f9'
                        : 'font-size: 18px; font-weight: 900; color: grey'
                    "
                  >
                    <v-icon>mdi-menu-right</v-icon>{{ items[3].text }}
                  </span>
                </v-col>
                <Transition>
                  <v-col
                    cols="12"
                    v-show="panel == 4"
                    style="text-align: right"
                  >
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                    >
                      <!-- {{ items[3].value }} -->
                      {{ value.mou | VMask(currencyMask) }} ครัวเรือน
                    </span>
                  </v-col>
                </Transition>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mt-4"
                  style="cursor: pointer"
                  @click="panel = 5"
                >
                  <span
                    :style="
                      panel == 5
                        ? 'font-size: 18px; font-weight: 900; color: #01a9f9'
                        : 'font-size: 18px; font-weight: 900; color: grey'
                    "
                  >
                    <v-icon>mdi-menu-right</v-icon>{{ items[4].text }}
                  </span>
                </v-col>
                <Transition>
                  <v-col
                    cols="12"
                    v-show="panel == 5"
                    style="text-align: right"
                  >
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                    >
                      <!-- {{ items[4].value }} -->
                      {{ value.kumAmount | VMask(currencyMask) }} คุ้ม
                    </span>
                  </v-col>
                </Transition>
              </v-row>

              <!-- {{ items[2].value }} -->
              <!-- <v-expansion-panels v-model="panel" class="mb-6" flat>
              <v-expansion-panel v-for="(item, i) in items" :key="i">
                <v-expansion-panel-header expand-icon="">
                  <span
                    v-if="panel !== 2"
                    style="font-size: 18px; font-weight: 900; color: red"
                  >
                    <v-icon>mdi-menu-right</v-icon>{{ item.text }}
                  </span>
                  <span
                    v-if="panel == 2"
                    style="font-size: 18px; font-weight: 900; color: #01a9f9"
                  >
                    <v-icon>mdi-menu-right</v-icon>{{ item.text }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="text-align: right">
                  <span
                    v-if="panel !== 2"
                    style="font-size: 18px; font-weight: 900; color: red"
                  >
                    {{ item.value }}
                  </span>
                  <span
                    v-if="panel == 2"
                    style="font-size: 18px; font-weight: 900; color: #01a9f9"
                  >
                    {{ item.value }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels> -->
            </v-col>
            <v-col cols="12" class="text-right">
              <span>ข้อมูล ณ วันที่ {{ convertDate(date) }} </span>
            </v-col>
            <v-col cols="12" class="pt-4">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <v-card
                class="pa-6 ma-2 mt-10"
                style="border-radius: 20px; background-color: #f2f4f7"
                elevation="0"
                v-if="value"
              >
                <v-row no-gutters>
                  <v-col cols="12" class="mt-4">
                    <span
                      style="font-weight: 900; color: #633fe4; font-size: 20px"
                      v-if="aaaaaa.area"
                    >
                      {{ aaaaaa.area }} , {{ aaaaaa.province }}
                      {{ aaaaaa.station }}
                    </span>
                    <span
                      v-else
                      style="font-weight: 900; color: #633fe4; font-size: 20px"
                      >ทั่วประเทศ
                    </span>
                  </v-col>
                  <!-- <v-col cols="12">
                  <span>กรุงเทพมหานคร </span><span> 10240</span>
                </v-col> -->
                  <v-col cols="12">
                    <span style="font-weight: 900; color: #633fe4"
                      ><b>{{ aaaaaa.village }}</b></span
                    >
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <span style="font-weight: 900; color: red"
                      >พบสารเสพติด
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <span style="font-size: 24px; font-weight: 900; color: red"
                      >{{ value.foundDrug | VMask(currencyMask) }} คน
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <span style="font-weight: 900; color: #01a9f9"
                      >ไม่พบสารเสพติด
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                      >{{ value.notFoundDrug | VMask(currencyMask) }} คน
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <span style="font-weight: 900; color: #01a9f9"
                      >ร้อยละ X-ray แล้ว
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                      >{{ value.xray100 }} %
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <span style="font-weight: 900; color: #01a9f9"
                      >ร้อยละผู้เข้าร่วมบำบัดโครงการ
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <span
                      style="font-size: 24px; font-weight: 900; color: #01a9f9"
                      >{{ value.joinUs100 }} %
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col id="map" cols="9">
          <v-row justify="center">
            <v-col cols="12">
              <GmapMap
                :center="center"
                :zoom="setZoom"
                map-style-id="roadmap"
                :options="mapOptions"
                style="width: 100%; height: 90vmin"
                ref="mapRef"
                @click="handleMapClick"
              >
                <GmapMarker
                  v-for="(m, index) in markers"
                  :key="index"
                  :position="m.position"
                  :clickable="true"
                  @click="panToMarker(m)"
                />
                <!-- @drag="handleMarkerDrag" -->
                <!-- :draggable="true" -->
                <!-- :icon="m.icon" -->
              </GmapMap>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});
export default {
  data: () => ({
    currencyMask,
    setZoom: 13,
    dialogfilter: false,
    panel: 1,
    data: {
      area: "",
      province: "",
      station: "",
      village: "",
    },
    items2: [
      { text: "ทั่วประเทศ", group: "" },
      { text: "นครบาล", group: "น." },
      { text: "ภาค1", group: "ภ.1" },
      { text: "ภาค2", group: "ภ.2" },
      { text: "ภาค3", group: "ภ.3" },
      { text: "ภาค4", group: "ภ.4" },
      { text: "ภาค5", group: "ภ.5" },
      { text: "ภาค6", group: "ภ.6" },
      { text: "ภาค7", group: "ภ.7" },
      { text: "ภาค8", group: "ภ.8" },
      { text: "ภาค9", group: "ภ.9" },
    ],
    items3: [],
    items4: [],
    items: [
      {
        icon: "mdi-inbox",
        text: "จำนวนประชากรจริง",
        value: 1111111,
      },
      {
        icon: "mdi-star",
        text: "เป้าหมายประชากรที่ X-Ray",
        value: 2222222,
      },
      {
        icon: "mdi-send",
        text: "ดำเนินการ X-Ray แล้ว",
        value: 3333333,
      },
      {
        icon: "mdi-send",
        text: "MOU ครัวเรือน",
        value: 4444444,
      },
      {
        icon: "mdi-send",
        text: "จำนวนคุ้ม",
        value: 5555555,
      },
    ],
    model: 1,

    riskForm: {
      name: "",
      latitude: "",
      longitude: "",
      descriptions: "",
      image_url: "",
      address: "",
      userId: 0,
    },
    markers: [],

    marker: {
      position: { lat: 13.7245601, lng: 100.4930261 },
      position: { lat: 13.7145601, lng: 100.5030261 },
    },
    center: { lat: 13.7245601, lng: 100.4930261 },

    mapOptions: {
      disableDefaultUI: true,
    },
    value: [],
    date: new Date().toISOString().substr(0, 10),
    aaaaaa: { village: "" },
  }),
  created() {
    this.data = {
      area: "",
      province: "",
      station: "",
      village: "",
    };
    this.center = { lat: 13.7245601, lng: 100.4930261 };
    this.value = [];
    this.panel = 1;
    // this.geolocate(); ///goto center
    this.getMarkers(); // getall marker
    // console.log("markers", this.markers);
    // this.searchDropdown();
    this.getData(); // getall number
  },
  methods: {
    gotoQR() {
      window.open(`https://admin.xn--72cfa4db8bjb7kqcd1r.online/SearchQR`);
    },
    gotoAdmin() {
      window.open(`https://admin.xn--72cfa4db8bjb7kqcd1r.online`);
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY ");
    },
    async getMarkers() {
      this.markers = [];
      const response = await this.axios.post(
        // https://adoring-pasteur.115-178-63-39.plesk.page/phase2/findAll?area=&province=&station=
        `${process.env.VUE_APP_API}/phase2/findAll?area=` +
          this.data.area +
          "&province=" +
          this.data.province +
          "&station=" +
          this.data.station
      );

      // console.log("response", response.data.data);
      const tempLatLong = response.data.data;
      for (let i = 0; i < tempLatLong.length; i++) {
        this.markers.push({
          // position: tempLatLong[i],
          position: {
            lat: parseFloat(tempLatLong[i].lat),
            lng: parseFloat(tempLatLong[i].lng),
          },
          icon: require("@/assets/pin.png"),
          title: "test title",
          area: tempLatLong[i].area,
          province: tempLatLong[i].province,
          station: tempLatLong[i].station,
        });
      }
      this.aaaaaa.area = this.data.area || "";
      this.aaaaaa.province = this.data.province || "";
      this.aaaaaa.station = this.data.station || "";
      // if (this.data.station) {
      // console.log("centerrrr", this.markers);
      if (this.data.area) {
        this.center = this.markers[0].position;
      }
      if (this.data.station) {
        this.setZoom = 15;
      } else if (this.data.area) {
        this.setZoom = 12;
      }
      // await this.panToMarker(this.markers);
      // }
    },
    async getData() {
      this.aaaaaa.village = "";
      this.getMarkers();
      const response = await this.axios.get(
        // `${process.env.VUE_APP_API}/summaryReport/findAll?station=${this.data.station}`
        `${process.env.VUE_APP_API}/summaryReport/getXrayReport?group=` +
          this.data.area +
          "&province=" +
          this.data.province +
          "&station=" +
          this.data.station
      );
      console.log("this.data", response.data.data);
      this.value.sumPeopleReal = response.data.data[0].sumPeopleReal;
      this.value.xray1 = response.data.data[0].xray1;
      this.value.xrayed = response.data.data[0].xrayed;
      this.value.mou = response.data.data[0].mou;
      this.value.kumAmount = response.data.data[0].kumAmount;

      this.value.foundDrug = response.data.data[0].foundDrug;
      this.value.notFoundDrug = response.data.data[0].notFoundDrug;
      this.value.xray100 = response.data.data[0].xray100;
      this.value.joinUs100 = response.data.data[0].joinUs100;
      if (this.data.station) {
        this.getStation(this.data.station);
      }
      this.dialogfilter = false;
      // this.aaaaaa.village = this.data.village || "";
    },
    async getStation(station) {
      const responseStation = await this.axios.get(
        `${process.env.VUE_APP_API}/stations`
      );

      console.log("stationitems", responseStation.data.data);
      var newArray = responseStation.data.data.filter(function (el) {
        return el.station == station;
      });
      this.data.village = newArray[0].village;

      this.aaaaaa.village = this.data.village || "";
      // console.log("newArray", newArray[0].village);
    },
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.data.province = "";
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` + this.data.area
      );
      this.items3 = response.data.data;

      // this.items4 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.data.province
      );
      this.items4 = response.data.data;
    },

    geolocate() {
      // navigator.geolocation.getCurrentPosition((position) => {
      //   this.marker.position = {
      //     lat: position.coords.latitude,
      //     lng: position.coords.longitude,
      //   };

      //   this.panToMarker();
      // });
      this.panToMarker();
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker(e) {
      // this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(18);
      console.log("e", e);

      if (e) {
        // this.data.area = e.area;
        // this.data.province = e.province;
        // this.data.station = e.station;
        this.getDataWhenClick(e);
      } else {
        this.getData();
      }
      this.center = e.position;
      // this.center = this.markers.position;
    },
    async getDataWhenClick(e) {
      this.setZoom = 15;
      console.log("setzoom click");
      this.aaaaaa.village = "";
      this.aaaaaa.area = e.area || "";
      this.aaaaaa.province = e.province || "";
      this.aaaaaa.station = e.station || "";
      const response = await this.axios.get(
        // `${process.env.VUE_APP_API}/summaryReport/findAll?station=${this.data.station}`
        `${process.env.VUE_APP_API}/summaryReport/getXrayReport?group=` +
          e.area +
          "&province=" +
          e.province +
          "&station=" +
          e.station
      );
      console.log("this.data", response.data.data);
      this.value.sumPeopleReal = response.data.data[0].sumPeopleReal;
      this.value.xray1 = response.data.data[0].xray1;
      this.value.xrayed = response.data.data[0].xrayed;
      this.value.mou = response.data.data[0].mou;
      this.value.kumAmount = response.data.data[0].kumAmount;

      this.value.foundDrug = response.data.data[0].foundDrug;
      this.value.notFoundDrug = response.data.data[0].notFoundDrug;
      this.value.xray100 = response.data.data[0].xray100;
      this.value.joinUs100 = response.data.data[0].joinUs100;

      if (e.station) {
        this.getStation(e.station);
      }

      this.dialogfilter = false;
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    },
    clear() {
      this.value = [];
      this.data = {
        area: "",
        province: "",
        station: "",
        village: "",
      };
    },
  },
};
</script>
<style  scoped>
.v-expansion-panel-header {
  align-items: start;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 18px 24px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-expansion-panel {
}
.v-expansion-panel-content__wrap {
  text-align: right;
}
.v-text-field--outlined >>> fieldset {
  border-radius: 20px;
}
.v-input__prepend-outer {
  width: 100px;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
